import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatBet } from '../../utils/utils';
import { useAccount, useWalletClient } from 'wagmi';
import { usePublicClient } from 'wagmi';

import { Avatar } from 'shared/components/ui/Avatar/Avatar';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { ROUTES } from 'shared/constants';
import { coinIcons } from 'shared/constants';
import { useBalance } from 'shared/hooks/useBalance';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useStartGameStore } from 'shared/store/start-game-store';
import { approveHandler, getAllowance } from 'shared/viem-functions/start-game';
import { CurrencyType } from 'shared/web3';

import ReturnIcon from 'assets/animation/buttons/return.svg?react';
import ControllerIcon from 'assets/controller-brown.svg?react';
import Game from 'assets/game.svg?react';

import './styles.scss';

interface LiveGameItemProps {
  roomId: bigint;
  address: `0x${string}`;
  currency?: CurrencyType;
  amount: number;
  ourGame: boolean;
  joinRoomHandler?: (roomId: number) => void;
}

export const LiveGameItem = ({
  roomId,
  address,
  currency,
  amount,
  ourGame,
  joinRoomHandler,
}: LiveGameItemProps) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { address: userAddress, chainId } = useAccount();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(min-width: 768px)');
  const [currentAllowance, setCurrentAllowance] = useState<number | null>(null);

  const provider = usePublicClient();
  const { data: walletClient } = useWalletClient();

  useEffect(() => {
    if (currency && userAddress && provider) {
      getAllowance({
        ownerAddress: userAddress,
        tokenAddress: currency.address,
        provider,
      }).then((allowance) => {
        setCurrentAllowance(Number(allowance) / 10 ** currency.decimals);
      });
    }
  }, [provider, currency, userAddress]);

  if (!currency) return null;

  const handleShowError = (errorMessage: string) => {
    setIsError(true);
    setErrorMessage(errorMessage);

    setTimeout(() => {
      setIsItemHovered(false);
      setIsError(false);
    }, 2000);
  };
  const { balance } = useBalance({
    currencyAddress: currency?.address,
    walletAddress: userAddress as `0x${string}`,
  });

  const handleJoinGame = async () => {
    if (balance && balance > amount / 10 ** currency!.decimals) {
      setLoading(true);
      try {
        if (Number(currentAllowance) < amount / 10 ** currency!.decimals) {
          if (!walletClient || !provider || !userAddress) return;
          if (currentAllowance === 0) {
            try {
              await approveHandler({
                amount: BigInt(amount * 10 ** currency.decimals),
                functionName: 'approve',
                currencyAddress: currency.address,
                userAddress,
                walletClient,
                publicClient: provider,
              });
            } catch (error) {
              console.error('Error in approve (allowance 0):', error);
              throw error;
            }
          } else if (amount > currentAllowance) {
            const amountToIncrease = amount - currentAllowance;
            try {
              await approveHandler({
                amount: BigInt(amountToIncrease * 10 ** currency.decimals),
                functionName: 'increaseAllowance',
                currencyAddress: currency.address,
                userAddress,
                walletClient,
                publicClient: provider,
              });
            } catch (error) {
              await approveHandler({
                amount: 0n,
                functionName: 'approve',
                currencyAddress: currency.address,
                userAddress,
                walletClient,
                publicClient: provider,
              });
              await approveHandler({
                amount: BigInt(amount * 10 ** currency.decimals),
                functionName: 'approve',
                currencyAddress: currency.address,
                userAddress,
                walletClient,
                publicClient: provider,
              });
            }
          }
          await getAllowance({
            ownerAddress: userAddress as `0x${string}`,
            tokenAddress: currency.address,
            provider,
          }).then((allowance) => {
            setCurrentAllowance(Number(allowance) / 10 ** currency.decimals);
          });
        }
        if (joinRoomHandler) {
          await joinRoomHandler(Number(roomId));
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setLoading(false);
      }
    } else {
      handleShowError(t('pages.liveGamesSection.lowBalanceError'));
    }
  };
  const handleShowJoinButton = () => {
    if (!isTablet) {
      return;
    }

    setIsItemHovered(true);
  };

  const handleHideJoinButton = () => {
    if (isError) {
      return;
    }
    setIsItemHovered(false);
  };

  return (
    <div className="w-full" onMouseEnter={handleShowJoinButton} onMouseLeave={handleHideJoinButton}>
      <div
        className={`xlg:grid xlg:grid-cols-12 flex  justify-between lg:gap-4 border-[1px] sm:border-[2px] bg-gradient-to-r border-[#FFFFFF40] px-[6px] sm:px-[12px] sm:p-[5px]
         ${ourGame ? 'from-[#05055B] to-[#4040CC]' : 'from-[#05055B] to-[#151552]'}
          rounded-[6px] sm:rounded-[12px] transition-all sm:h-[72px] h-[32px] cursor-not-allowed`}
      >
        <div className="col-span-4 flex items-center items gap-[12px]">
          <Avatar
            userAddress={`0x${address}`}
            className={`sm:w-[48px] sm:h-[48px] w-[20px] h-[20px] rounded-[4px] sm:rounded-[12px]`}
          />
          <div className="flex flex-col">
            <span className="text-[#FFA700] sm:text-[20px] text-[9px] font-medium">
              {address.substring(0, 6)}......
              {address.substring(address.length - 4)}
            </span>
            <span className="text-[#F7F7F7] text-[9px] sm:text-[16px] font-medium opacity-75">
              {t('pages.liveGamesSection.id')} {roomId.toString()}
            </span>
          </div>
        </div>
        {/* <div className="text-[#F7F7F7] font-semibold flex items-center sm:text-[20px] text-[10px] col-span-2 flex gap-2 items-center">
          1 mins ago
        </div> */}
        <div className="text-[#F7F7F7] font-semibold flex items-center text-[9px] sm:text-[20px] col-span-2">
          {formatBet(amount, currency!.decimals)}
        </div>
        <div className="text-[#F7F7F7] font-semibold flex items-center sm:text-[20px] text-[10px] col-span-2  gap-2">
          <div className="w-[14px] h-[14px] sm:w-[28px] sm:h-[28px]">
            {currency?.symbol && coinIcons[currency.symbol as keyof typeof coinIcons] && (
              <img
                src={coinIcons[currency.symbol as keyof typeof coinIcons]}
                className="w-[14px] h-[14px] sm:w-[28px] sm:h-[28px]"
              />
            )}
          </div>

          {currency?.symbol}
        </div>
        {ourGame ? (
          <PrimaryButton
            onClick={() => navigate(ROUTES.ROOM + `/${chainId}/${Number(roomId)}`)}
            isLoading={loading}
            text="common.return"
            svg={!isError && <ReturnIcon className="w-5 h-5 sm:w-6 sm:h-6" />}
            className="sm:w-[192px] 2xl:w-full col-span-4"
            buttonType="return"
          />
        ) : isItemHovered || loading ? (
          <PrimaryButton
            onClick={handleJoinGame}
            isLoading={loading}
            text={isError ? errorMessage : 'common.joinGame'}
            svg={!isError && <ControllerIcon className="w-5 h-5 sm:w-6 sm:h-6" />}
            className="sm:w-[192px] 2xl:w-full col-span-4"
          />
        ) : (
          <div
            className={`sm:w-[192px] 2xl:w-full  h-[48px] align-center transition-all flex justify-center items-center col-span-4  opacity-50 mt-[4px] `}
            onClick={handleJoinGame}
          >
            <div className="flex text-white text-[14px] sm:text-[20px] font-semibold items-center gap-3">
              {isError ? (
                errorMessage
              ) : (
                <>
                  <Game className="w-6 h-6" />
                  {t('common.joinGame')}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
