import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  lobby: {
    createGameOpen: boolean;
  };
  music: {
    playing: boolean;
    volume: number;
  };
}

interface Actions {
  setLobbyCreateGameOpen: (open: boolean) => void;
  setMusicPlaying: (playing: boolean) => void;
  setMusicVolume: (volume: number) => void;
}

const initialState: State = {
  lobby: {
    createGameOpen: false,
  },
  music: {
    playing: true,
    volume: 0.8,
  },
};

export const useUIStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...initialState,
      setLobbyCreateGameOpen: (open: boolean) => {
        set((state) => {
          state.lobby.createGameOpen = open;
        });
      },
      setMusicPlaying: (playing: boolean) => {
        set((state) => {
          state.music.playing = playing;
          state.music.volume = playing ? 0.8 : 0;
        });
      },
      setMusicVolume: (volume: number) => {
        set((state) => {
          state.music.volume = volume;
        });
      },
    })),
    { name: 'uiStore' },
  ),
);
