import React, { useRef, useEffect } from 'react';

import { useUIStore } from 'shared/store/uiStore';

export const BackgroundMusic = ({ musicFile }) => {
  const audioRef = useRef(null);
  const hasUnlockedAudio = useRef(false);
  const { music } = useUIStore();

  useEffect(() => {
    const audio = audioRef.current;
    if (music.playing) {
      // Try to play immediately if already unlocked
      audio
        .play()
        .then(() => {
          hasUnlockedAudio.current = true;
        })
        .catch(() => {
          if (!hasUnlockedAudio.current) {
            const startAudio = () => {
              audio.play().then(() => {
                hasUnlockedAudio.current = true;
              });
              window.removeEventListener('click', startAudio);
            };
            window.addEventListener('click', startAudio);
          }
        });
    } else {
      audio.pause();
    }

    return () => {
      window.removeEventListener('click', () => {}); // ensure cleanup, though our listener is already removed in the handler
    };
  }, [music.playing]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      // Ensure volume is clamped between 0 and 1
      console.log('music.volume', music.volume);
      audio.volume = Math.max(0, Math.min(music.volume, 1));
    }
  }, [music.volume]);

  return <audio ref={audioRef} src={musicFile} loop preload="auto" />;
};

export default BackgroundMusic;
