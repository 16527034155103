import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'wagmi';

import { CreateGameSection, LiveGamesSection } from 'modules/lobby-page/components';

import DesktopLayout from 'shared/components/layout/desktop-layout';

interface DesktopViewProps {
  approveAllowanceHandler: (fullAllowance: boolean, bet: number) => void;
  startGameHandler: (bet: number) => void;
  joinRoomHandler: (roomId: number) => void;
}

export const DesktopView = ({
  approveAllowanceHandler,
  startGameHandler,
  joinRoomHandler,
}: DesktopViewProps) => {
  const { t } = useTranslation();
  const { chainId } = useAccount();
  return (
    <DesktopLayout title={t('pages.lobby')}>
      <div className="flex justify-end lg:justify-between gap-2 xlg:gap-8 flex-col lg:flex-row">
        <LiveGamesSection key={chainId} joinRoomHandler={joinRoomHandler} />
        <CreateGameSection
          approveAllowanceHandler={approveAllowanceHandler}
          startGameHandler={startGameHandler}
        />
      </div>
    </DesktopLayout>
  );
};
