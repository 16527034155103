// import { RoomModals } from 'modules/Room/RoomModals';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';
import DesktopRoom from 'modules/Room/pages/DesktopRoom';
import MobileRoom from 'modules/Room/pages/MobileRoom';

import { ChangeNetwork } from 'shared/components/sections/change-network/change-network';
import { ConnectWallet } from 'shared/components/sections/connect-wallet/connect-wallet';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { BackgroundMusic } from 'shared/components/ui/background-music';
import { ROUTES } from 'shared/constants';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { ModalTypeEnum } from 'shared/constants/modalEnums';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useRoomStore } from 'shared/store/roomStore';

import game from 'assets/sounds/game.wav';

export const Room = () => {
  const { isConnected, chainId, address } = useAccount();
  const { activeRoomId } = useParams();
  const { playerA, playerB } = useRoomStore();
  const { showSignModal } = useShowSignModal();
  const isMobile = useMediaQuery('(max-width: 1199px)');
  const { networkId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address !== NULL_ADDRESS &&
      playerB.address !== address?.toLowerCase()
    ) {
      console.log('watch game');
      navigate(`${ROUTES.STREAM}/${networkId}/${activeRoomId}`);
    }
  }, [playerA, playerB, address, activeRoomId, networkId]);

  if (!isConnected) {
    return <ConnectWallet title={'room.connect'} description={'room.connectDesc'} />;
  }

  if (Number(networkId) !== chainId) {
    return <ChangeNetwork title={'room.switch'} description={'room.switchDesc'} />;
  }
  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      <BackgroundMusic musicFile={game} />
      {isMobile ? <MobileRoom /> : <DesktopRoom />}
    </>
  );
};
