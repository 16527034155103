import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from 'usehooks-ts';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';

import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { BackgroundMusic } from 'shared/components/ui/background-music';
import { ROUTES } from 'shared/constants';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGlobalStore } from 'shared/store/globalStore';
import { useStartGameStore } from 'shared/store/start-game-store';

import lobby from 'assets/sounds/lobby.wav';

import { DesktopView, MobileView } from './views';

export const LobbyPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const invitedBy = useGlobalStore((state) => state.invitedBy);

  const { address, isConnected, chainId } = useAccount();
  const { showSignModal } = useShowSignModal();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isConnected) {
      navigate(ROUTES.HOME);
    }
  }, [isConnected]);
  const { approveAllowance, startGame, joinRoom, fetchCurrentAllowance } = useStartGameStore();
  const { selectedCurrency } = useCurrenciesStore();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  useEffect(() => {
    if (publicClient && selectedCurrency && address) {
      fetchCurrentAllowance(address, selectedCurrency, publicClient);
    }
  }, [publicClient, selectedCurrency, address]);
  const approveAllowanceHandler = async (fullAllowance: boolean, bet: number) => {
    if (!walletClient || !publicClient || !selectedCurrency) return;
    await approveAllowance({
      amount: +bet,
      currency: selectedCurrency,
      userAddress: address as `0x${string}`,
      walletClient,
      publicClient,
      fullAllowance,
      joinGame: false,
    });
  };

  const startGameHandler = async (bet: number) => {
    if (!walletClient || !publicClient || !selectedCurrency || !bet) return;
    let output = await startGame({
      wins: 3,
      currency: selectedCurrency.address,
      betAmount: BigInt(Math.floor(+bet * 10 ** selectedCurrency!.decimals)),
      playerB: NULL_ADDRESS,
      referrer: invitedBy,
      walletClient,
      publicClient,
      userAddress: address as `0x${string}`,
    });
    if (output) {
      if (
        typeof window !== 'undefined' &&
        window?.sa_event &&
        typeof window.sa_event === 'function'
      ) {
        window.sa_event('create-room', { wallet: address.substring(2) });
      } else {
        console.warn('Simple Analytics tracking function is not available.');
      }
      setTimeout(() => {
        navigate(`${ROUTES.ROOM}/${chainId}/${output}`);
      }, 1000);
    }
  };

  const joinRoomHandler = async (roomId: number) => {
    if (!walletClient || !publicClient || !selectedCurrency) return;
    try {
      await joinRoom({
        roomId: BigInt(roomId),
        referrer: invitedBy,
        walletClient,
        publicClient,
        userAddress: address as `0x${string}`,
      });
      if (
        typeof window !== 'undefined' &&
        window?.sa_event &&
        typeof window.sa_event === 'function'
      ) {
        window.sa_event('join-room', { wallet: address.substring(2) });
      } else {
        console.warn('Simple Analytics tracking function is not available.');
      }
      setTimeout(() => {
        navigate(`${ROUTES.ROOM}/${chainId}/${roomId}`);
      }, 500);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      <BackgroundMusic musicFile={lobby} />

      {isMobile ? (
        <MobileView
          key={chainId}
          approveAllowanceHandler={approveAllowanceHandler}
          startGameHandler={startGameHandler}
          joinRoomHandler={joinRoomHandler}
        />
      ) : (
        <DesktopView
          key={chainId}
          approveAllowanceHandler={approveAllowanceHandler}
          startGameHandler={startGameHandler}
          joinRoomHandler={joinRoomHandler}
        />
      )}
    </>
  );
};
